.link-menu {
    color: #212529 !important;
}

.link-menu:hover {
    color: #8abe57 !important;
}

.link-menu:active {
    color: #8abe57 !important;
}

.publish-service:hover {
    background-color: #8abe57 !important;
    border-color: #8abe57 !important;
}

.subscribe{
    padding-inline: 19px !important;
}

.subscribe:hover {
    background-color: #8abe57 !important;
    border-color: #8abe57 !important;
}

.publish-service:active {
    background-color: #8abe57 !important;
    border-color: #8abe57 !important;
}

.reebit-title{
    font-style: normal;
    font-size: 64px;
    line-height: 120%;
    letter-spacing: 0em;
}


.reebit-btn-primary {
    background-color: #8abe57 !important;
    border-color: #8abe57 !important;
    padding-inline: 19px !important;
}

.reebit-btn-primary:hover {
    background-color: #9FD362  !important;
    border-color: #9FD362  !important;
}

.reebit-btn-primary:hover {
    background-color: #9FD362  !important;
    border-color: #9FD362  !important;
}

.know-reebit {
    padding-inline: 19px !important;
}

.know-reebit:hover {
    background-color: #8abe57   !important;
    border-color: #8abe57   !important;
}

.know-reebit:active {
    background-color: #8abe57   !important;
    border-color: #8abe57   !important;
}

.carousel-indicators {
    margin-bottom: -1rem !important;
}

.carousel-indicators button {
    width: 39px !important;
    height: 5px !important;
}

.carousel-indicators .active {
    opacity: 1;
    background-color: #8ABE57 !important;
}

.navbar-toggler {
    border: none !important;
    padding: 0px !important;
}

.link-footer-reebit:hover {
    color: #9FD362 !important;
}

.link-footer-reebit:active {
    color: #9FD362 !important;
}

.link-rrss-reebit:hover {
    fill: #9FD362 !important;
}

.link-rrss-reebit:active {
    fill: #9FD362 !important;
}